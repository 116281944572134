import { type InternalLink, type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconSwap } from '@ds/icons/IconSwap'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/constants'
import { MODAL_NAMES } from '~/scopes/product/constants'

import { type Prefixes as PickerPrefixes } from '../../components/LargePicker/LargePicker.types'

import translations from './tradeIn.translations'

export const getTradeInStep = (
  i18n: ReturnType<typeof useI18n>,
  route: InternalLink,
  swapStatus: string,
  swapOffer?: Estimation,
  ppDiscountedSwap = 'noVariation',
  defaultPrice: Price | undefined = undefined,
) => {
  const isSwapInCart =
    swapStatus === SWAP_SUCCESS && Boolean(swapOffer?.hasOffer)

  let optionNoPrice
  let optionYesPrice
  let tag

  if (isSwapInCart) {
    if (ppDiscountedSwap === 'withDiscountedSwap') {
      optionNoPrice = defaultPrice
      optionYesPrice = swapOffer?.price
    } else {
      optionYesPrice = swapOffer?.offerPrice
    }
  }

  if (ppDiscountedSwap !== 'withDiscountedSwap') {
    tag = isSwapInCart
      ? i18n(translations.tradeInTagAccepted)
      : i18n(translations.tradeInTag)
  }

  return {
    id: 'tradein',
    type: 'service',
    title: translations.titleStepTradeIn,
    titleEmphasis: translations.titleEmphasisStepTradeIn,
    trackingId: 'swap',
    desktopIllustration: '/img/product/funnel/step-trade-in-square.svg',
    guidance: {
      label: translations.tradeInGuidance,
      icon: IconSwap,
      modalName: MODAL_NAMES.TRADE_IN,
    },
    options: [
      {
        value: 'off',
        label: translations.tradeInOptionNo,
        price: optionNoPrice,
        trackingValue: 'no',
        available: true,
        acquirable: true,
        // No is selected is no trade in offer is accepted. This means that we change the value only
        // if the user goes through the whole flow and ACCEPTS the offer
        selected: swapStatus === SWAP_SUCCESS && !swapOffer?.hasOffer,
        // We need to have a link prop to have the right button styling, but trade in has absolutely
        // no impact on the URL, so we keep the current route as href.
        link: route,
        style: {
          prefix: 'circle' as PickerPrefixes,
        },
      },
      {
        value: 'on',
        label: translations.tradeInOptionYes,
        // subLabel and tag values changes if the user accepted the offer.
        subLabel: i18n(translations.tradeInOptionYesDescription, {
          price: swapOffer?.price,
        }),
        tag,
        price: optionYesPrice,
        trackingValue: 'yes',
        available: true,
        acquirable: true,
        selected: isSwapInCart,
        link: route,
        dataQaAttribute: 'swap-cta',
        style: {
          prefix: 'circle' as PickerPrefixes,
        },
      },
    ],
  }
}
